<template>
    <div class="order-history">
      <h2>주문 기록</h2>
      <p>주문 기록을 여기에 표시합니다.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'OrderHistory',
  };
  </script>
  
  <style scoped>
  .order-history {
    padding: 20px;
  }
  </style>
  